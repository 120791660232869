export default function AboutUsWindow() {
  return (
    <div
      className="h-auto min-h-max bg-no-repeat bg-cover max-h-fit"
      style={{ backgroundImage: "url('/backgroundImage.png')" }}
    >
      <div className="max-w-screen-xl mx-auto h-full py-10 min-h-fit auto-rows-fr">
        <div className="md:grid grid-cols-3 gap-4">
          {" "}
          <img
            src="/InteriorImage5.jpeg"
            alt="Neon sign reading Pizza is always the answer"
            className="lg:block hidden"
          />
          <div className="h-full grow-2 md:col-span-2">
            <div className="flex flex-col justify-around rounded-lg p-6 h-full shadow-xl bg-black bg-opacity-60 min-h-max">
              <h2 className="text-4xl font-bold text-white mb-4 text-center font-playfair m-1">
                THE HOUSE OF QUALITY & TASTE
              </h2>
              <p className="text-white leading-relaxed text-lg font-merriweather">
                2 for 1 Pizza House has a rich history steeped in family
                tradition. Our journey began in Alberta, Canada, as a humble
                single store owned and operated by a passionate family with a
                love for pizza. Over the years, our dedication to crafting the
                finest pizzas and providing exceptional service led to our
                expansion. Now, we proudly serve all Fresno customers, offering
                them a taste of our heritage and the flavors that have made us a
                beloved pizza brand. Despite our growth, we remain true to our
                roots as a family-owned business, ensuring that every pizza we
                serve carries the same passion and dedication.
              </p>
              <a
                href="/menu"
                className="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded mt-4 text-center"
              >
                View Our Menu
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
